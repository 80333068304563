<template>
  <div class="work-page nike-women">
  	<!-- Header -->
  	<section class="header">

      <img v-lazy="'/static/images/NWN_1.jpg'"/>
    
  		<div class="meta">
  			<p class="client">NIKE</p>
  			<a class="project">WOMEN</a>
  		</div>
  		<div class="copy container">
  			<div class="col-1">
  				<h1>Stop Exercising. Start Training.</h1>
  			</div>
  			<div class="col-2">
  				<p>Exercise is pointing motion; training is focused energy. A training campaign for Nike Women and the Nike Metcon 3 - the ultimate cross training shoe.</p>
  			</div>
  		</div>
  	</section>

    <div class="spacer-lg"></div>

    <div class="two-col" style="justify-content: space-between; align-items: flex-start; margin-top: 7%">
      <div class="col" style="width: 90%;">
        <img src="/static/images/NWN_2.jpg"/>
      </div>
      <div class="col" style="width: 22%; position: relative; z-index: 2;">
        <img src="/static/images/NWN_3.jpg" style="width: 140%; margin-left: -40%; margin-top: -30%;"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <div class="two-col" style="justify-content: space-between; align-items: flex-start;">
      <div class="col" style="width: 22%; position: relative; z-index: 2;">
        <img src="/static/images/NWN_5.jpg" style="width: 140%; margin-right: -40%; margin-top: 160%;"/>
      </div>
      <div class="col" style="width: 90%;">
        <img src="/static/images/NWN_4.jpg"/>
      </div>
    </div>  

    <div class="standout-copy" style="text-align: left; width: 60%; float: right; margin-top: -10%">
      Our imagery featured athletes from multiple codes: Netball, football, AFLW and Ironwomen.
    </div>
    <div class="clear"></div>

    <div class="spacer-lg" style="margin-top: 3%;"></div>

    <img src="/static/images/NWN_6.jpg"/>

    <div class="spacer-lg"></div>

    <div style="width: 40%; float: right; margin-right: 5%; margin-bottom: -10%">
      <video 
        width="100%" 
        playsinline
        class="nike-women-video"
        loop
        autoplay
        muted>
        <source src="/static/videos/NW_VIDEO.mp4" type="video/mp4">
      </video>
    </div>

    <img src="/static/images/NWN_7.jpg"/>



    <div class="footer">
      <div class="prev">
        <router-link to="/wsw">
          <img src="/static/images/prev.svg"/>
        </router-link>
      </div>
      <div class="footer-contact">
        <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
        <span>&copy; {{date}} by Blood UTD</span>
      </div>
      <div class="next">
        <router-link to="/ca-watch-me-play">
          <img src="/static/images/next.svg"/>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nike-women',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  }
}
</script>

<style lang="scss" scoped>

.nike-women {

  .no-negative-top-mobile {
    @media(max-width: 680px) {
      
      .col:first-child {
         width: 39% !important;
         z-index: 2 !important;
        img {
          width: 60% !important;
          margin-right: -10% !important;
          margin-top: 10% !important;
        }
      }

      .col:last-child {
        width: 59% !important;
      } 
    }
  }

}

</style>
